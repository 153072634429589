import { useEffect, useRef } from "react";
import { useStore } from "../../store/store";
import { DataChannelStatus, StreamingStatus } from "./gameConnectionTypes";
import { WSStatus } from "./websocket/websocketTypes";

export function useConnectionAnalytics() {
  const streamingStatus = useStore(
    (state) => state.gameConnection.streamingStatus
  );
  const dataChannelStatus = useStore(
    (state) => state.gameConnection.dataChannelStatus
  );
  const socketStatus = useStore((state) => state.gameConnection.wsStatus);
  const hasTrackedQueueRequest = useRef(false);

  // Track the first queue request only once.
  // The offer is tracked by the backend.
  // TODO: We should track the instance request with the backend.
  // We can send the request counter to only track the first request.
  useEffect(() => {
    if (!hasTrackedQueueRequest.current) {
      window.analytics?.track(
        "queue",
        {
          type: "queue",
          name: "instance_requested",
        },
        {
          plugins: {
            // Disable this specific track call all plugins except Logemon
            all: false,
            logemon: true,
          },
        }
      );
      hasTrackedQueueRequest.current = true;
    }
  }, []);

  // Track the socket opening
  useEffect(() => {
    if (socketStatus === WSStatus.OPEN) {
      window.analytics?.track("connection", {
        type: "connection",
        name: "socket_connected",
      });
    }
  }, [socketStatus]);

  // Track the data channel opening
  useEffect(() => {
    if (dataChannelStatus === DataChannelStatus.DATA_CHANNEL_CONNECTED) {
      window.analytics?.track("connection", {
        type: "connection",
        name: "stream_connected",
      });
    }
  }, [dataChannelStatus]);

  // Track the stream starting
  useEffect(() => {
    if (streamingStatus === StreamingStatus.PLAYING) {
      window.analytics?.track("connection", {
        type: "connection",
        name: "stream_playing",
      });
    }
  }, [streamingStatus]);
}
