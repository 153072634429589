import { ReactNode, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { isInstagramAgent } from "../constants/flags";
import { breakpoints } from "../style/theme";

export const useWindowSize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const debouncedOnResize = debounce(
      () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      },
      500,
      {
        leading: true,
        trailing: true,
      }
    );
    window.addEventListener("resize", debouncedOnResize);
    return () => window.removeEventListener("resize", debouncedOnResize);
  }, []);
  return {
    width,
    height,
  };
};

export const useBreakpoint = () => {
  const { width } = useWindowSize();

  const isPhoneWidth =
    breakpoints.number.xs <= width && width < breakpoints.number.s;
  const isTabletWidth =
    breakpoints.number.s <= width && width < breakpoints.number.m;
  const isLaptopWidth =
    breakpoints.number.m <= width && width < breakpoints.number.l;
  const isDesktopWidth =
    breakpoints.number.l <= width && width < breakpoints.number.xl;
  const isLargeDesktopWidth = breakpoints.number.xl <= width;

  return {
    isPhoneWidth,
    isTabletWidth,
    isLaptopWidth,
    isDesktopWidth,
    isLargeDesktopWidth,
  };
};

/** Check if we are on a small screen, like mobile portrait or
landscape but not a medium tablet, where we should display
our alternative layout. */
export const useIsSmallScreen = () => {
  const { width: w, height: h } = useWindowSize();

  const mobileLongSideMax = breakpoints.number.m;
  const mobileShortSideMax = breakpoints.number.s;

  const isSmallerThanMobileLandscape =
    w < mobileLongSideMax && h < mobileShortSideMax;
  const isSmallerThanMobilePortrait =
    w < mobileShortSideMax && h < mobileLongSideMax;

  const isSmallScreen =
    isSmallerThanMobileLandscape || isSmallerThanMobilePortrait;

  return isSmallScreen;
};

/** Returns true for landscape (ratio bigger than 1:1)
 *  and false for portrait (ratio equal or smaller than 1:1). */
export const useIsLandscape = () => {
  const { width, height } = useWindowSize();
  return width / height > 1;
};

type UseAnimatedShowHideStateArgs = {
  currentState: AnimatedState;
  /** Milliseconds */
  hidingAnimationDuration: number;
  onShowDone?: () => void;
  onHideDone?: () => void;
};

type AnimatedState = "show" | "hide";

/** Animation helper useful to delay the unmount state untill the animation finishes. */
export const useAnimatedShowHideState = ({
  currentState,
  hidingAnimationDuration,
  onShowDone,
  onHideDone,
}: UseAnimatedShowHideStateArgs) => {
  const [animationState, setAnimationState] =
    useState<AnimatedState>(currentState);
  const [isHiding, setIsHiding] = useState(false);

  const shouldShow = !isHiding && currentState !== animationState;
  const shouldHide = isHiding;

  const startHiding = () => {
    setIsHiding(true);
    setAnimationState("hide");
  };

  // Defer the opening of a small ammount to trigger the animation.
  useEffect(() => {
    let id: number | undefined;
    if (shouldShow) {
      if (!id)
        id = window.setTimeout(() => {
          setAnimationState("show");
          onShowDone?.();
        }, 10);
    }
    return () => {
      if (!id) window.clearTimeout(id);
    };
  }, [shouldShow, onShowDone]);

  // Defer the closing for the duration of the animation.
  useEffect(() => {
    let id: number | undefined;
    if (shouldHide) {
      if (!id)
        id = window.setTimeout(() => {
          setAnimationState("hide");
          setIsHiding(false);
          onHideDone?.();
        }, hidingAnimationDuration + 1);
    }
    return () => {
      if (!id) window.clearTimeout(id);
    };
  }, [shouldHide, hidingAnimationDuration, onHideDone]);

  return {
    animation: animationState,
    startHiding,
    isHiding,
  };
};

type ReflowSetup = {
  normal: ReactNode;
  small?: ReactNode;
  landscape?: ReactNode;
  portrait?: ReactNode;
  // Some additional custom conditions to check.
  extra?: Array<{
    condition: boolean;
    component: ReactNode;
  }>;
};

/**
 * Return the correct component depending on the current breakpoint and ratio.
 * "landscape" and "portrait" fallback to "small".
 * "small" fallbacks to "normal".
 */
export const useReflow = (setup: ReflowSetup): ReactNode => {
  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();

  const { normal, small, landscape, portrait, extra } = setup;

  if (extra?.length) {
    for (const { condition, component } of extra) {
      if (condition) return component;
    }
  }

  if (isSmallScreen) {
    // The instagram browser glitches when the keyboard opens
    // because it crops the window from portrait to landscape.
    // This rerenders the component and the text input loses focus.
    // We will only return portrait for mobile instagram to fix this,
    // since instagram doesn't rotate to landscape either.
    if (isInstagramAgent) return portrait ?? small ?? normal;

    if (isLandscape) {
      return landscape ?? small ?? normal;
    } else {
      return portrait ?? small ?? normal;
    }
  } else {
    return normal;
  }
};
