import { ReactNode, forwardRef, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { CardTestIds } from "../../constants/testIds";
import Glass from "../atoms/Glass";
import Scroll from "../atoms/Scroll";
import CloseButton from "./CloseButton";

const CloseWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
`;

const TopWrapper = styled.div<{ $height?: string }>`
  border-top-right-radius: ${(p) => p.theme.radiusBig};
  border-top-left-radius: ${(p) => p.theme.radiusBig};
  height: ${(p) => p.$height ?? "auto"};
`;

const BottomWrapper = styled.div`
  border-bottom-right-radius: ${(p) => p.theme.radiusBig};
  border-bottom-left-radius: ${(p) => p.theme.radiusBig};
  overflow: hidden;
  height: auto;
`;

const Padding = styled.div<{ $top?: string; $bottom?: string }>`
  padding-right: 5px;
  padding-left: 5px;
  padding-top: ${(p) => p.$top ?? "0px"};
  padding-bottom: ${(p) => p.$bottom ?? "0px"};
`;

export interface Props {
  width?: string;
  height?: string;
  maxHeight?: string;
  testId?: string;
  noPadding?: boolean;
  border?: string;
  floatingSection?: ReactNode;
  topSection?: ReactNode;
  topSectionHeight?: string;
  bottomSection?: ReactNode;
  brightCloseButton?: boolean;
  hideScrollButton?: boolean;
  showFade?: boolean;
  hideScrollSpace?: boolean;
  style?: React.CSSProperties;
  onClose?: () => void;
  children?: ReactNode;
}

/** A powerful component to display many types of scrollable-closable card-like content. */
const Card = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      width = "416px",
      height,
      maxHeight,
      noPadding,
      topSectionHeight = "auto",
      floatingSection,
      topSection,
      testId,
      bottomSection,
      border,
      style,
      onClose,
      brightCloseButton,
      hideScrollButton,
      showFade,
      hideScrollSpace = false,
    },
    ref
  ) => {
    const theme = useTheme();
    const [bottomWrapperElement, setBottomWrapperElement] =
      useState<HTMLDivElement | null>(null);
    const [topWrapperElement, setTopWrapperElement] =
      useState<HTMLDivElement | null>(null);

    const [computedHeight, setComputedHeight] = useState<string | undefined>(
      height
    );
    const [computedMaxHeight, setComputedMaxHeight] = useState<
      string | undefined
    >(maxHeight);

    useEffect(() => {
      // We need to recalculate the height when the top or bottom section changes
      const top = topWrapperElement?.offsetHeight ?? 0;
      const bottom = bottomWrapperElement?.offsetHeight ?? 0;
      const computedHeight = height
        ? `calc(${height} - ${top + bottom + 2}px)`
        : "auto";
      const computedMaxHeight = maxHeight
        ? `calc(${maxHeight} - ${top + bottom + 2}px)`
        : "unset";
      setComputedMaxHeight(computedMaxHeight);
      setComputedHeight(computedHeight);
    }, [
      topSection,
      bottomSection,
      bottomWrapperElement,
      height,
      maxHeight,
      topWrapperElement?.offsetHeight,
    ]);

    const radiusPadding = theme.radiusBig !== "0px" ? 15 : 0;
    const buttonPadding = onClose ? 40 : 0;
    const paddingTop = topSection ? 0 : radiusPadding + buttonPadding;
    const paddingBottom = bottomSection ? 0 : radiusPadding;

    return (
      <Glass
        testId={testId}
        width={width}
        height={height ?? "auto"}
        padding="0px"
        ref={ref}
        border={border}
        overflow="hidden"
        style={style}
        hasTransition
      >
        {floatingSection && <div>{floatingSection}</div>}
        {topSection && (
          <TopWrapper $height={topSectionHeight} ref={setTopWrapperElement}>
            {topSection}
          </TopWrapper>
        )}
        <Scroll
          height={computedHeight}
          maxHeight={computedMaxHeight}
          barPaddingTop={paddingTop}
          barPaddingBottom={paddingBottom}
          fadeTop={Boolean(topSection || floatingSection)}
          hideScrollToBottom={hideScrollButton}
          showFade={showFade}
          hideScrollSpace={hideScrollSpace}
        >
          {noPadding
            ? Boolean(children) && (
                <Padding
                  $top={topSection ? "0px" : "10px"}
                  $bottom={bottomSection ? "0px" : "8px"}
                >
                  {children}
                </Padding>
              )
            : children}
        </Scroll>
        {bottomSection && (
          <BottomWrapper ref={setBottomWrapperElement}>
            {bottomSection}
          </BottomWrapper>
        )}
        {Boolean(onClose) && (
          <CloseWrapper>
            <CloseButton
              testId={CardTestIds.closeBtn}
              onClick={onClose}
              isGlass={brightCloseButton}
            />
          </CloseWrapper>
        )}
      </Glass>
    );
  }
);

export default Card;
