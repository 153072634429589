import { styled } from "styled-components";
import Button from "../../../components-ui/atoms/Button";
import Icon from "../../../components-ui/atoms/Icon";
import Row from "../../../components-ui/atoms/Row";
import Space from "../../../components-ui/atoms/Space";
import Spatial from "../../../components-ui/atoms/Spatial";
import Typo from "../../../components-ui/atoms/Typo";
import Card from "../../../components-ui/molecules/Card";
import { PanelTestIds } from "../../../constants/testIds";
import { Props } from "./Settings.ui";
import BottomBar from "./component/BottomBar";
import { useGetSettingsVideoHeight } from "./subpages/settingsVideo/hooks/useGetSettingsVideoHeight";

const Content = styled.div`
  padding: 18px 18px 8px;
`;

const SettingsUiNormal: React.FC<Props> = ({
  children,
  onClose,
  onPrevious,
  title,
  subPageStyles,
  links,
  locales,
  selectedLocaleCode,
  bottomBarChildren,
  onShowStats,
  openLanguagePanel,
  openReportPanel,
  subpage,
}) => {
  const { height } = useGetSettingsVideoHeight(subpage);
  const content = (
    <Content>
      <Row gap={2} style={{ padding: "12px 0px" }}>
        {onPrevious && (
          <Button.Tertiary
            onClick={onPrevious}
            circular
            testId={PanelTestIds.settings.common.previousBtn}
          >
            <Icon.Chevron size="1rem" rotate="-90deg" shiftY="-0.1rem" />
          </Button.Tertiary>
        )}
        <Typo.Title style={subPageStyles?.title}>{title}</Typo.Title>
      </Row>
      <Space h={6} />
      {children}
    </Content>
  );

  return (
    <Spatial tiltX={-6} rim glare dragMotion mouseTiltX mouseTiltY mouseZoom>
      <Card
        testId={PanelTestIds.settings.normal.root}
        onClose={onClose}
        height={height}
        hideScrollButton
        bottomSection={
          <BottomBar
            links={links}
            onShowStats={onShowStats}
            openLanguagePanel={openLanguagePanel}
            openReportPanel={openReportPanel}
            locales={locales}
            selectedLocaleCode={selectedLocaleCode}
          >
            {bottomBarChildren}
          </BottomBar>
        }
        style={subPageStyles?.card}
      >
        {content}
      </Card>
    </Spatial>
  );
};

export default SettingsUiNormal;
