import { styled } from "styled-components";
import Transition from "../../../components-ui/atoms/Transition";
import { BaseLayout } from "../../../core/layout/PanelsLayout";
import { useIsSmallScreen } from "../../../hooks/ui";
import { useStore } from "../../../store/store";
import { zIndex } from "../../../style/theme";
import SettingsLogic from "./Settings.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  z-index: ${zIndex.panels};
`;

const SettingsPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.settings.visible);
  const subpage = useStore((s) => s.layout.panels.settings.subpage);
  const isSmallscreen = useIsSmallScreen();

  return (
    <Layout id="panel-settings">
      <Transition
        height="100%"
        width="100%"
        watch={[visible, subpage]}
        slideIn={isSmallscreen ? undefined : "right"}
        slideOut={isSmallscreen ? undefined : "right"}
        justify="flex-end"
        align="flex-start"
      >
        {visible && <SettingsLogic subpage={subpage} />}
      </Transition>
    </Layout>
  );
};

export default SettingsPanel;
