import { ReactNode, useMemo } from "react";
import { EnvironmentConfigResponseDto } from "@journee-live/gyarados/axios/esm";
import { useTheme } from "styled-components";
import {
  useCurrentHelpScreenImage,
  useIsDarkMode,
} from "../../../../../api/gyaradosHooks";
import { getImageUrl } from "../../../../../api/gyaradosUtils";
import ControlsDefaultImage from "../../../../../assets/svg/ControlsDefaultImage.svg";
import ControlsDefaultImageDark from "../../../../../assets/svg/ControlsDefaultImageDark.svg";
import Icon, {
  BaseIconProps,
  IconName,
} from "../../../../../components-ui/atoms/Icon";
import { useEnvironmentContext } from "../../../../../core/EnvironmentFetcher.core";
import { useText } from "../../../../../core/i18n/i18nHooks";
import i18nKeys from "../../../../../core/i18n/i18nKeys";

type ControlElementConfiguration = {
  label: keyof typeof i18nKeys;
  description?: keyof typeof i18nKeys;
  type: "leftButton" | "rightButton" | "gesture";
  isEnabled: (config: EnvironmentConfigResponseDto) => boolean;
  iconName?: IconName;
  iconProps?: BaseIconProps;
};

export type ControlElement = {
  label: string;
  description?: string;
  icon?: ReactNode;
};

const defaultButtonIconProps: BaseIconProps = { size: "14px" };
const defaultGestureIconProps: BaseIconProps = {
  size: "18px",
  inheritColor: true,
};

const controlElementConfigurations: ControlElementConfiguration[] = [
  {
    label: "button_label_chat",
    type: "leftButton",
    isEnabled: (config: EnvironmentConfigResponseDto) => !config.disableChat,
    iconName: "ChatFilled",
    iconProps: defaultButtonIconProps,
  },
  {
    label: "button_label_mode",
    type: "leftButton",
    isEnabled: (config: EnvironmentConfigResponseDto) =>
      config.movements &&
      Object.values(config.movements).find((enabled) => enabled),
    iconName: "PersonWalkingFilled",
    iconProps: defaultButtonIconProps,
  },
  {
    label: "button_label_interact",
    type: "leftButton",
    isEnabled: (config: EnvironmentConfigResponseDto) =>
      config.emojis && Object.keys(config.emojis).length > 0,
    iconName: "HandWaveFilled",
    iconProps: defaultButtonIconProps,
  },
  {
    label: "button_label_camera",
    type: "rightButton",
    isEnabled: (config: EnvironmentConfigResponseDto) =>
      config.allowPhotoCapture,
    iconName: "CameraFilled",
    iconProps: defaultButtonIconProps,
  },
  {
    label: "button_label_map",
    type: "rightButton",
    isEnabled: (config: EnvironmentConfigResponseDto) =>
      config.hasMapNavigation,
    iconName: "MapFilled",
    iconProps: { size: "15px" },
  },
  {
    label: "button_label_quests",
    type: "rightButton",
    isEnabled: (config: EnvironmentConfigResponseDto) =>
      config.quests.length > 0,
    iconName: "CircleStar",
    iconProps: { size: "15px" },
  },
  {
    label: "button_label_settings",
    type: "rightButton",
    isEnabled: () => true,
    iconName: "GearFilled",
    iconProps: defaultButtonIconProps,
  },
  {
    label: "gesture_label_move_avatar",
    description: "gesture_label_description_move_avatar",
    type: "gesture",
    isEnabled: () => true,
  },
  {
    label: "gesture_label_move_camera",
    description: "gesture_label_description_move_camera",
    type: "gesture",
    isEnabled: () => true,
  },
  {
    label: "gesture_label_walk",
    description: "gesture_label_description_walk",
    type: "gesture",
    iconName: "PersonWalking",
    iconProps: defaultGestureIconProps,
    isEnabled: () => false,
  },
  {
    label: "gesture_label_fly",
    description: "gesture_label_description_fly",
    type: "gesture",
    iconName: "PersonTeleport",
    iconProps: defaultGestureIconProps,
    isEnabled: () => false,
  },
  {
    label: "gesture_label_hoverboard",
    description: "gesture_label_description_hoverboard",
    type: "gesture",
    iconName: "PersonSliding",
    iconProps: defaultGestureIconProps,
    isEnabled: () => false,
  },
  {
    label: "gesture_label_interactions",
    description: "gesture_label_description_interactions",
    type: "gesture",
    isEnabled: (config: EnvironmentConfigResponseDto) =>
      config.emojis && Object.keys(config.emojis).length > 0,
    iconName: "HandWave",
    iconProps: defaultGestureIconProps,
  },
];

type ControlKeyConfiguration = {
  label: keyof typeof i18nKeys;
  keys: string[];
  isEnabled: (config: EnvironmentConfigResponseDto) => boolean;
};

export type ControlKey = {
  label: string;
  keys: string[];
};

const controlKeyConfigurations: ControlKeyConfiguration[] = [
  {
    label: "control_key_move_avatar",
    keys: ["W", "A", "S", "D"],
    isEnabled: () => true,
  },
  {
    label: "control_key_fly_mode",
    keys: ["F"],
    isEnabled: (config) => config.movements?.enableFly || false,
  },
  {
    label: "control_key_hoverboard",
    keys: ["B"],
    isEnabled: (config) => config.movements?.enableHoverboard || false,
  },
  {
    label: "control_key_sprint",
    keys: ["Shift"],
    isEnabled: () => true,
  },
];

export function useControls(): {
  leftButtons: ControlElement[];
  rightButtons: ControlElement[];
  touchButtons: ControlElement[];
  keys: ControlKey[];
  gestures: ControlElement[];
  image: string;
} {
  const theme = useTheme();
  const isDarkMode = useIsDarkMode();
  const helpScreenImage = useCurrentHelpScreenImage();
  const { environment } = useEnvironmentContext();
  const t = useText();

  return useMemo(() => {
    const defaultImage = isDarkMode
      ? ControlsDefaultImageDark
      : ControlsDefaultImage;
    const image = getImageUrl(helpScreenImage, 300, 300) ?? defaultImage;

    const leftButtons: ControlElement[] = [];
    const rightButtons: ControlElement[] = [];
    const touchButtons: ControlElement[] = [];
    const gestures: ControlElement[] = [];

    for (const config of controlElementConfigurations) {
      if (config.isEnabled(environment)) {
        const IconElement = config.iconName ? Icon[config.iconName] : undefined;
        const controlElement = {
          label: t(config.label),
          description: config.description && t(config.description),
          icon: IconElement && (
            <IconElement color={theme.colorAbove2} {...config.iconProps} />
          ),
        };
        switch (config.type) {
          case "leftButton":
            leftButtons.push(controlElement);
            touchButtons.push(controlElement);
            break;
          case "rightButton":
            rightButtons.push(controlElement);
            touchButtons.push(controlElement);
            break;
          case "gesture":
            gestures.push(controlElement);
            break;
        }
      }
    }

    const keys = controlKeyConfigurations
      .filter((config) => config.isEnabled(environment))
      .map((config) => ({
        label: t(config.label),
        keys: config.keys,
      }));

    return {
      leftButtons,
      rightButtons,
      touchButtons,
      keys,
      gestures,
      image,
    };
  }, [environment, helpScreenImage, isDarkMode, t, theme.colorAbove2]);
}
