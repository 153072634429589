import { GyaradosSDK } from "@journee-live/gyarados";
import { FromGameMessagesSchema } from "../../../core/gameConnection/messages/fromGameMessages";
import { useReflow } from "../../../hooks/ui";
import { FilterType } from "./Quest.logic";
import QuestUiLandscape from "./Quest.ui.landscape";
import QuestUiNormal from "./Quest.ui.normal";
import QuestUiPortrait from "./Quest.ui.portrait";

export type QuestButtonFilter = {
  type: keyof typeof FilterType;
  active: boolean;
  onClick: () => void;
};

export type Props = {
  handleDismiss: () => void;
  buttonFilters: Array<QuestButtonFilter>;
  quests: (GyaradosSDK.QuestResponseDto &
    FromGameMessagesSchema["QuestsInfo"]["quests"][number])[];
  isLoadingQuests: boolean;
};

const QuestUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <QuestUiNormal {...props} />,
    portrait: <QuestUiPortrait {...props} />,
    landscape: <QuestUiLandscape {...props} />,
  });
};

export default QuestUi;
