import { styled } from "styled-components";
import Button from "../../../components-ui/atoms/Button";
import Space from "../../../components-ui/atoms/Space";
import Spatial from "../../../components-ui/atoms/Spatial";
import Typo from "../../../components-ui/atoms/Typo";
import Card from "../../../components-ui/molecules/Card";
import { useText } from "../../../core/i18n/i18nHooks";
import { Props } from "./Report.ui";
import ReportForm from "./ReportForm";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: block;
  text-align: center;

  & > div {
    margin: 12px 6px;
  }
`;

const ReportUiNormal: React.FC<Props> = ({
  formRef,
  isLoading,
  error,
  onSubmit,
  onClose,
  isConferenceEnabled,
}) => {
  const t = useText();

  return (
    <Spatial rim glare dragMotion mouseTiltX mouseTiltY mouseZoom>
      <Card
        onClose={onClose}
        bottomSection={
          <ButtonWrapper>
            <Button.Primary
              buttonType="submit"
              onClick={onSubmit}
              disabled={isLoading}
            >
              {t("report_submit")}
            </Button.Primary>
          </ButtonWrapper>
        }
      >
        <BodyWrapper>
          <Space h={4} />
          <Typo.Title size="1rem">{t("report_title")}</Typo.Title>
          <Space h={2} />
          {error && <Typo.Note color="red">{error || ""}</Typo.Note>}
          <Space h={2} />
          <ReportForm
            formRef={formRef}
            isConferenceEnabled={isConferenceEnabled}
          />
        </BodyWrapper>
      </Card>
    </Spatial>
  );
};

export default ReportUiNormal;
