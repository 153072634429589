import Space from "../../../components-ui/atoms/Space";
import CardFullPortrait from "../../../components-ui/molecules/CardFullPortrait";
import { useText } from "../../../core/i18n/i18nHooks";
import { Props } from "./Quest.ui";
import QuestFilter from "./component/QuestFilter";
import QuestHeader from "./component/QuestHeader";
import QuestList from "./component/QuestList";

const QuestUiPortrait: React.FC<Props> = ({
  handleDismiss,
  buttonFilters,
  isLoadingQuests,
  quests,
}) => {
  const t = useText();

  return (
    <CardFullPortrait
      testId={"normal"}
      noPadding
      hideScrollButton
      onClose={handleDismiss}
    >
      <QuestHeader
        title={t("quest_panel_title")}
        description={t("quest_panel_description")}
      />
      <Space h={4} />
      <QuestFilter buttonFilters={buttonFilters} />
      <Space h={4} />
      <QuestList isLoadingQuests={isLoadingQuests} quests={quests} />
    </CardFullPortrait>
  );
};

export default QuestUiPortrait;
