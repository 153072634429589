import { styled } from "styled-components";
import Button from "../../../components-ui/atoms/Button";
import Space from "../../../components-ui/atoms/Space";
import Typo from "../../../components-ui/atoms/Typo";
import CardFullLandscape from "../../../components-ui/molecules/CardFullLandscape";
import { useText } from "../../../core/i18n/i18nHooks";
import { Props } from "./Report.ui";
import ReportForm from "./ReportForm";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  box-sizing: border-box;
  padding: 0 1.5rem;
  font-size: ${(p) => p.theme.t};
`;

const ReportUiLandscape: React.FC<Props> = ({
  formRef,
  isLoading,
  error,
  onSubmit,
  onClose,
  isConferenceEnabled,
}) => {
  const t = useText();

  return (
    <CardFullLandscape onClose={onClose}>
      <BodyWrapper>
        <Space h={4} />
        <Typo.Title size="1rem">{t("report_title")}</Typo.Title>
        <Space h={4} />
        {error && <Typo.Note color="red">{error || ""}</Typo.Note>}
        <Space h={2} />
        <ReportForm
          formRef={formRef}
          isConferenceEnabled={isConferenceEnabled}
        />
        <Footer>
          <Button.Primary
            buttonType="submit"
            onClick={onSubmit}
            disabled={isLoading}
          >
            {t("report_submit")}
          </Button.Primary>
        </Footer>
      </BodyWrapper>
    </CardFullLandscape>
  );
};

export default ReportUiLandscape;
