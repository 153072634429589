import { isMobile } from "../constants/flags";
import ActionBarPanelLayout from "../features/actionBarPanel/ActionBarPanelLayout";
import CinematicModePanelLayout from "../features/cinematicModePanel/CinematicModePanelLayout";
import MediaSharePanel from "../features/mediaCapture/mediaShare/MediaShare.panel";
import PhotoCapturePanel from "../features/mediaCapture/photoCapture/PhotoCapture.panel";
import VideoCapturePanel from "../features/mediaCapture/videoCapture/VideoCapture.panel";
import DevOptionsPanel from "../features/panels/devOptions/DevOptions.panel";
import ForceLandscapePanel from "../features/panels/forceLandscape/ForceLandscape.panel";
import FullscreenVideoPanelLayout from "../features/panels/fullscreenVideo/FullscreenVideo.panel";
import HintPanel from "../features/panels/hint/Hint.panel";
import InfoCardPanel from "../features/panels/infoCard/InfoCard.panel";
import LanguagePanel from "../features/panels/language/Language.panel";
import MapPanel from "../features/panels/map/Map.panel";
import PollPanel from "../features/panels/poll/Poll.panel";
import PopupPanel from "../features/panels/popup/Popup.panel";
import PresentationBarPanel from "../features/panels/presentationBar/PresentationBar.panel";
import ProfilePanel from "../features/panels/profile/Profile.panel";
import { PROFILE_MAX_HEIGHT } from "../features/panels/profile/lib/constants";
import QuestPanel from "../features/panels/quest/Quest.panel";
import QuestHintPanel from "../features/panels/questHint/QuestHint.panel";
import ReportPanel from "../features/panels/report/Report.panel";
import ScreenSharingPanel from "../features/panels/screenSharing/ScreenSharing.panel";
import SettingsPanel from "../features/panels/settings/Settings.panel";
import SocialPanel from "../features/panels/social/Social.panel";
import StatsPanel from "../features/panels/stats/Stats.panel";
import TextChatPreview from "../features/panels/textChatPreview/TextChatPreview.panel";
import VideoAvatarsPanel from "../features/panels/videoAvatars/VideoAvatars.panel";
import WalletConnectModalPanel from "../features/panels/walletConnectModal/WalletConnectModal.panel";
import TopLogoPanelLayout from "../features/topLogoPanel/TopLogoPanelLayout";
import { useWindowSize } from "../hooks/ui";
import PanelsLayout from "./layout/PanelsLayout";

const ExperiencePage: React.FC = () => {
  const { height } = useWindowSize();
  const isShortDesktopScreen = !isMobile && height < PROFILE_MAX_HEIGHT;

  return (
    <PanelsLayout
      panels={[
        {
          component: <ActionBarPanelLayout />,
          name: "actionBar",
          normal: {
            rules: ["experienceOnly"],
            zone: "bottom",
          },
        },
        {
          component: <TopLogoPanelLayout />,
          name: "logo",
          normal: {
            rules: ["experienceOnly", "ghost"],
            zone: "top",
          },
        },
        {
          component: <QuestHintPanel />,
          name: "questHint",
          normal: {
            rules: ["experienceOnly", "stubborn"],
            zone: "top",
          },
          small: {
            rules: ["experienceOnly"],
            zone: "top",
          },
        },
        {
          component: <HintPanel />,
          name: "hint",
          normal: {
            rules: ["experienceOnly", "stubborn"],
            zone: "top",
          },
          small: {
            rules: ["experienceOnly"],
            zone: "top",
          },
        },
        {
          component: <ProfilePanel />,
          name: "profile",
          normal: {
            // Profile panel is fullscreen on short desktop screens
            // until we decide on panel responsiveness
            zone: isShortDesktopScreen ? "overlay" : "right",
            rules: ["experienceOnly", "solo"],
          },
          small: {
            rules: ["experienceOnly", "solo"],
            zone: "overlay",
          },
        },
        {
          component: <SettingsPanel />,
          name: "settings",
          normal: {
            zone: "right",
            rules: ["experienceOnly", "ghost"],
          },
          small: {
            rules: ["experienceOnly", "solo"],
            zone: "overlay",
          },
        },
        {
          component: <MapPanel />,
          name: "map",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <PhotoCapturePanel />,
          name: "photo",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <VideoCapturePanel />,
          name: "videoCapture",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <MediaSharePanel />,
          name: "mediaShare",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <PopupPanel />,
          name: "popup",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <SocialPanel />,
          name: "social",
          normal: {
            zone: "left",
            rules: ["experienceOnly"],
          },
          small: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <InfoCardPanel />,
          name: "infocard",
          normal: {
            zone: "right",
            rules: ["experienceOnly"],
          },
          small: {
            zone: "overlay",
            rules: ["experienceOnly"],
          },
        },

        {
          component: <CinematicModePanelLayout />,
          name: "cinematicView",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo", "stubborn"],
          },
        },
        {
          component: <StatsPanel />,
          name: "stats",
          normal: {
            zone: "extra",
            rules: ["global", "ghost"],
          },
        },

        {
          component: <DevOptionsPanel />,
          name: "devOptions",
          normal: {
            zone: "video",
            rules: ["experienceOnly", "ghost"],
          },
        },
        {
          component: <ForceLandscapePanel />,
          name: "forceLandscape",
          normal: {
            zone: "overlay",
            rules: ["global", "ghost", "solo", "stubborn"],
          },
        },
        {
          component: <FullscreenVideoPanelLayout />,
          name: "fullscreenVideo",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo", "stubborn"],
          },
        },
        {
          component: <ScreenSharingPanel />,
          name: "screenSharing",
          normal: {
            zone: "video",
            rules: ["experienceOnly"],
          },
        },
        {
          component: <VideoAvatarsPanel />,
          name: "videoAvatars",
          normal: {
            rules: ["experienceOnly", "ghost"],
            zone: "top",
          },
        },
        {
          component: <PollPanel />,
          name: "poll",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
          small: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <TextChatPreview />,
          name: "textChatPreview",
          normal: {
            zone: "left",
            rules: ["experienceOnly"],
          },
        },
        {
          component: <WalletConnectModalPanel />,
          name: "walletConnect",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <PresentationBarPanel />,
          name: "presentationBar",
          normal: {
            rules: ["experienceOnly"],
            zone: "overlay",
          },
        },
        {
          component: <LanguagePanel />,
          name: "language",
          normal: {
            rules: ["global"],
            zone: "overlay",
          },
        },
        {
          component: <ReportPanel />,
          name: "report",
          normal: {
            rules: ["global"],
            zone: "overlay",
          },
        },
        {
          component: <QuestPanel />,
          name: "quest",
          normal: {
            rules: ["experienceOnly"],
            zone: "right",
          },
          small: {
            rules: ["experienceOnly"],
            zone: "overlay",
          },
        },
      ]}
    />
  );
};

export default ExperiencePage;
