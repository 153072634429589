import { Fragment } from "react";
import sanitizeHtml from "sanitize-html";
import styled from "styled-components";
import Typo from "../../../../../components-ui/atoms/Typo";
import { useText } from "../../../../../core/i18n/i18nHooks";
import { useStore } from "../../../../../store/store";
import { steps } from "../../../../../style/theme";
import { TextChatMessage as TextChatMessageType } from "./textChatTypes";
import { linkify } from "./textChatUtils";

const MessageContainer = styled.div<{ $isSelf: boolean }>`
  line-height: ${steps.font.f20.lineHight};
  font-weight: ${steps.fontWeight.semiBold};
  direction: ${(p) => (p.$isSelf ? "rtl" : "ltr")};
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
`;

const TextContainer = styled.div`
  /* height: 24px; */
  width: 80%;
  word-break: break-word;
  display: flex;
  align-items: center;
  & > p {
    margin: 0;
    padding: ${steps.spacing[1]} ${steps.spacing[2]};
    border-radius: ${(p) => p.theme.radiusSmall};
    color: ${(p) => p.theme.colorAbove3};
    background-color: ${(p) => p.theme.colorAbove0};
    direction: ltr;
  }
`;

const SenderId = styled.span`
  font-size: ${(p) => p.theme.textS};
  margin-bottom: 0.1em;
  margin-left: 0.2em;
`;

const Deleted = styled.span`
  font-size: ${(p) => p.theme.textS};
  color: red;
  margin-bottom: 0.1em;
  margin-left: 0.5em;
`;

const NaughtyWarning = styled.span`
  font-size: ${(p) => p.theme.textS};
  margin-bottom: 0.1em;
  margin-left: 0.2em;
`;

type Props = {
  message: TextChatMessageType;
  withModeration?: boolean;
  onDelete?: () => void;
  isDeleted?: boolean;
  isPopUpMessage?: boolean;
};

const TextChatMessage: React.FC<Props> = ({
  message,
  withModeration,
  onDelete,
  isDeleted,
}) => {
  const t = useText();
  const playerId = useStore((s) => s.gameConnection.playerId);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const isSelf =
    message.senderId === playerId &&
    (message.roomId ? message.roomId === roomId : true);
  const isSystem = message.senderId === -1;
  const lines = message.content.split("\n").filter((line) => line.length);

  return (
    <MessageContainer $isSelf={isSelf}>
      {!isSelf && !isSystem && (
        <>
          {withModeration && message.isNaughty && (
            <NaughtyWarning>{`⚠️ ${t("chat_blocked")}`}</NaughtyWarning>
          )}
          {withModeration && (
            <SenderId>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {t("chat_id")}: ${message.senderId}
            </SenderId>
          )}
          {withModeration &&
            (isDeleted ? (
              <Deleted>{t("chat_deleted")}</Deleted>
            ) : (
              <button onClick={onDelete}>{t("chat_delete")}</button>
            ))}
        </>
      )}
      {lines.map((l, i) => (
        <Fragment key={i}>
          <TextContainer>
            <p data-private>
              <Typo.Body>
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(linkify(l), {
                      allowedTags: ["a"],
                      allowedAttributes: { a: ["href", "target"] },
                    }),
                  }}
                />
              </Typo.Body>
            </p>
          </TextContainer>
        </Fragment>
      ))}
    </MessageContainer>
  );
};

export default TextChatMessage;
