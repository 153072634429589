import Column from "../../../../components-ui/atoms/Column";
import Icon from "../../../../components-ui/atoms/Icon";
import Row from "../../../../components-ui/atoms/Row";
import Typo from "../../../../components-ui/atoms/Typo";
import { useText } from "../../../../core/i18n/i18nHooks";
import { Props } from "../Quest.ui";
import QuestCard from "./QuestCard";

const QuestList: React.FC<Omit<Props, "handleDismiss" | "buttonFilters">> = ({
  isLoadingQuests,
  quests,
}) => {
  const t = useText();

  return (
    <>
      <Column align="center" gap={1}>
        {isLoadingQuests ? (
          <Row width="100%" justify="center">
            <Icon.RipplesAnimated />
          </Row>
        ) : (
          quests.map((quest) => <QuestCard key={quest.id} quest={quest} />)
        )}
      </Column>
      {quests.length === 0 && (
        <Column align="center" justify="center" height="40px">
          <Typo.Body>{t("quest_panel_no_quests")}</Typo.Body>
        </Column>
      )}
    </>
  );
};

export default QuestList;
